import React, { useEffect } from "react";
import Helmet from "react-helmet";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { navigate } from "gatsby";
import Butter from "buttercms";
import { Button } from "@material-ui/core";
import SEO from "../components/seo";
import { grey, indigo } from "@material-ui/core/colors";

const butter = Butter("09ba7d6ba30c1ad49fcdae19b8bed0ce840ca696");

export default () => {
  const matches = useMediaQuery("(min-width:1350px)");
  const [localState, setLocalState] = React.useState({
    posts: [],
  });

  const getFDAposts = () => {
    butter.post
      .list({
        page: 1,
        page_size: 10,
        exclude_body: "true",
        category_slug: "fda",
      })
      .then((response) => {
        setLocalState({ posts: response.data.data });
      });
  };

  useEffect(() => {
    getFDAposts();
  }, []);

  return (
    <>
      <SEO
        title="FDA Compliance"
        description="LabLog&#039;s built-in tools simplify FDA 21 CFR 11 compliance for biotech startups."
      />

      <div
        style={{
          background: "linear-gradient(90deg, #4b6cb7 0%, #182848 100%)",
          display: "flex",
          paddingLeft: matches ? "150px" : "25px",
          paddingRight: matches ? "150px" : "25px",
        }}
      >
        <Box mt="75px" mb="75px">
          <Box
            component="h1"
            fontWeight="500"
            color="#ffffff"
            fontSize="h4.fontSize"
          >
            <Box>21 CFR Part 11</Box>
            <Box>Verified Compliance</Box>
          </Box>
          <Box color="#FFFFFF" fontWeight="250">
            We take care of compliance for you, so you can focus on your
            research and application submissions.
          </Box>
        </Box>
      </div>
      <Container maxWidth="md" component="div">
        <Box
          color="#3d3d3d"
          component="h3"
          textAlign="left"
          fontSize="h5.fontSize"
        >
          What is Title 21 CFR Part 11?
        </Box>
        <Box
          color="#3d3d3d"
          component="div"
          textAlign="left"
          fontSize="h6.fontSize"
        >
          This set of regulations from the U.S. Food and Drug Agency (FDA)
          defines criteria under which electronic records and signatures are
          considered reliable, trustworthy, and equivalent to their paper
          equivalents.
          <br />
          <br />
          In the recently released draft industry guidance titled,{" "}
          <a href="https://www.fda.gov/regulatory-information/search-fda-guidance-documents/use-electronic-records-and-electronic-signatures-clinical-investigations-under-21-cfr-part-11">
            &#34;Use of Electronic Records and Electronic Signatures in clinical
            Investigations under 21 CFR Part 11 - Questions and Answers&#34;
          </a>
          , the FDA provides the following recommendation:
          <br />
          <blockquote cite="https://www.fda.gov/regulatory-information/search-fda-guidance-documents/use-electronic-records-and-electronic-signatures-clinical-investigations-under-21-cfr-part-11">
            <i>
              &#34;Sponsors and other regulated entities often perform audits of
              the vendor&#39;s electronic systems and products to assess the
              vendor&#39;s design and development methodologies used in the
              construction of the electronic system or the product, as well as
              the vendor&#39;s validation documentation. To reduce the time and
              cost burden, sponsors and other regulated entities should consider
              periodic, but shared audits conducted by trusted third
              partied.&#34;
            </i>
          </blockquote>
          <br />
          LabLog regularly undergoes independent audits performed by qualified
          third-party accredited assessors with regard to 21 CFR Part 11. LabLog
          provides customers with access to the latest audit reports, which
          customers may review during their vendor assessment process.
          <br />
        </Box>
        <br />
        <Box
          color="#3d3d3d"
          component="h3"
          textAlign="left"
          fontSize="h5.fontSize"
        >
          Audit Trails
        </Box>
        <Box
          color="#3d3d3d"
          component="p"
          textAlign="left"
          fontSize="h6.fontSize"
        >
          <br />
          In recently released guidelines by the FDA the agency emphesized the
          importance of Electronic record audit trails during inspections.
          <br />
          <br />
          At LabLog we have given a lot of consideration to audit trails and we
          have created a robust solution for automated creation of audit trails.
          All audit trails are retained for 10 years or a period chosen by the
          customer. Requesting audit trails for a record is as simple as
          clicking a button in the LabLog app.
          <br />
          <br />
          LabLog uses a two-tier system to capture changes to critical data and
          log all activity that is performed on our platform.
          <br />
        </Box>
        <br />
        <Box
          color="#3d3d3d"
          component="h3"
          textAlign="left"
          fontSize="h5.fontSize"
        >
          Systems Access Controls
        </Box>
        <Box
          color="#3d3d3d"
          component="p"
          textAlign="left"
          fontSize="h6.fontSize"
        >
          Set access controls according to your organization’s security posture.
          LagLog uses role-based access controls so that administrators can
          limit data access to authorized individuals.
          <br />
          We include built-in compliance roles like reviewer and approver, and
          administrators can also create custom roles for their teams. Plus, we
          encrypt all records to give data owners maximum control over
          distribution and access rights.
        </Box>
        <br />
        <Box
          color="#3d3d3d"
          component="h3"
          textAlign="left"
          fontSize="h5.fontSize"
        >
          Local and Cloud-Backed Record Protection
        </Box>
        <Box
          color="#3d3d3d"
          component="p"
          textAlign="left"
          fontSize="h6.fontSize"
        >
          Our electronic notebook software prioritizes data security. We provide
          a full audit trail and full redundancy so that you can be certain that
          your laboratory complies with all necessary regulations. You can read
          more about our data security policy
          <a href="/app-security"> here</a> or download our product{" "}
          <a href="/request-whitepaper">whitepaper</a>.
        </Box>
        <br />
        <Box
          color="#3d3d3d"
          component="h3"
          textAlign="left"
          fontSize="h5.fontSize"
        >
          Electronic signatures
        </Box>
        <Box
          color="#3d3d3d"
          component="p"
          textAlign="left"
          fontSize="h6.fontSize"
        >
          LabLog makes it easy to request and sign records. Signature
          information is captured and displayed according to the requirements of
          21 CFR Part 11.
        </Box>
        <br />
        <Box
          color="#3d3d3d"
          component="h3"
          textAlign="left"
          fontSize="h5.fontSize"
        >
          Secure Time-Stamp Generation
        </Box>
        <Box
          color="#3d3d3d"
          component="p"
          textAlign="left"
          fontSize="h6.fontSize"
        >
          Generating time-stamps for creations and edits has never been easier.
          LagLog automates the secure time-stamp generation process.
        </Box>
        <br />
        <Box
          color="#3d3d3d"
          component="h3"
          textAlign="left"
          fontSize="h5.fontSize"
        >
          Training and SOPs
        </Box>
        <Box
          color="#3d3d3d"
          component="p"
          textAlign="left"
          fontSize="h6.fontSize"
        >
          At LabLog we have created a set of SOPs specifically for compliance to
          Title 21 part 11. Furthermore, our entire team undergoes regular
          training to ensure that everyone is aware of the latest requirements
          and how to meet those requirements within their role at LabLog.
        </Box>
        <br />
        <Box
          color="#3d3d3d"
          component="h4"
          textAlign="center"
          fontSize="h4.fontSize"
        >
          Request a one-on-one demo today to learn more
        </Box>
        <Box style={{ flexBox: true, justifyContent: "center", mt: "35px" }}>
          {/* eslint jsx-a11y/anchor-is-valid:0 */}

          <Button
            onClick={() => navigate("/request-demo")}
            variant="contained"
            color="primary"
            disableElevation
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "80px",
              marginBottom: "30px",
            }}
          >
            Schedule Demo
          </Button>
        </Box>

        <Container
          style={{
            marginTop: "150px",
            padding: matches ? "50px" : "20px",
            backgroundColor: indigo[50],
          }}
        >
          <Box
            textAlign="center"
            component="h3"
            m={1}
            color="#3d3d3d"
            fontSize="h5.fontSize"
          >
            We have perform impartial analysis to identify the procedural and
            technical controls that are required to satisfy the regulatory
            requirements of U.S. FDA 21 CFR Part 11, both internally within
            LabLog and externally for LabLog life sciences customers.
          </Box>
        </Container>
        <div
          style={{
            zIndex: "1000",
            position: "relative",
            margin: "auto",
            width: "0",
            height: "0",
            borderLeft: "20px solid transparent",
            borderRight: "20px solid transparent",
            borderTop: `30px solid ${indigo[50]}`,
          }}
        />
        <Container
          style={{
            marginTop: "-30px",
            padding: "40px",
            backgroundColor: grey[100],
          }}
        >
          <Box component="p" m={1} color="#3d3d3d" fontSize="h6.fontSize">
            <strong>Trust Criteria and Cloud Controls Matrix (CCM)</strong>
            <br />
            The Trust and CCM Criteria pertain to trust service principles and
            criteria that are met by control activities provided by LabLog.
          </Box>
        </Container>
        <div
          style={{
            zIndex: "1000",
            position: "relative",
            margin: "auto",
            width: "0",
            height: "0",
            borderLeft: "20px solid transparent",
            borderRight: "20px solid transparent",
            borderTop: `30px solid ${grey[100]}`,
          }}
        />
        <Container
          style={{
            marginTop: "-30px",
            padding: "40px",
            backgroundColor: indigo[50],
          }}
        >
          <Box component="p" m={1} color="#3d3d3d" fontSize="h6.fontSize">
            <strong>Shared Responsibilities</strong>
            <br />
            We identify the shared responsibilities between Labog and its life
            sciences customers for meeting regulatory requirements, such as FDA
            21 CFR Part 11 Electronic Records, Electronic Signatures (21 CFR
            Part 11).
          </Box>
        </Container>
        <div
          style={{
            zIndex: "1000",
            position: "relative",
            margin: "auto",
            width: "0",
            height: "0",
            borderLeft: "20px solid transparent",
            borderRight: "20px solid transparent",
            borderTop: `30px solid ${indigo[50]}`,
          }}
        />
        <Container
          style={{
            marginTop: "-30px",
            padding: "40px",
            backgroundColor: grey[50],
          }}
        >
          <Box component="div" m={1} color="#3d3d3d" fontSize="h6.fontSize">
            <p>
              <strong>Limit Systems Access to Authorized Users</strong>
            </p>
            <p>
              Our comprehensive access controls give you the tools you need to
              give your users the exact amount of privileges that they need to
              do their jobs.
            </p>
          </Box>
          <br />
        </Container>
        <br />
        <br />
        <p>
          <strong>More articles like this:</strong>
        </p>
        {localState.posts.map((post) => (
          <p key={post.url}>
            &bull;&nbsp;
            <a href={post.url}>{post.title}</a>
          </p>
        ))}
      </Container>
    </>
  );
};
